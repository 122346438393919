import * as React from "react";
import Layout from "../../../components/Layout";
import { faArrowCircleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StaticImage } from "gatsby-plugin-image";
import ScopriAltriBonusECM from "../../../components/ScopriAltriBonusECM/ScopriAltriBonusECM";

export default function EmergenzaSanitariaPage() {
  return (
    <Layout
      pageTitle="Bonus ECM - Emergenza sanitaria"
      description="Bonus ECM destinato a tutti i professionisti sanitari che hanno continuato a lavorare durante l'emergenza sanitaria"
    >
      <section className="position-relative">
        <StaticImage
          src="../../../images/background-bonus-crediti.png"
          className="img-header-home"
          alt="Bonus ECM Emergenza sanitaria"
          title="Bonus ECM Emergenza sanitaria"
        />
        <div className="container-fluid px-0 py-5 container-absolute-450 d-flex flex-column justify-content-center">
          <div className="container py-5">
            <h1
              title="Bonus ECM per Professionisti Sanitari che hanno continuato a lavorare
              durante l'emergenza sanitaria"
              className="text-center text-white display-3"
            >
              Bonus ECM per Professionisti Sanitari che hanno continuato a
              lavorare durante l'emergenza sanitaria
            </h1>
            <p
              className="text-center text-white"
              style={{ fontSize: 22, fontWeight: 400 }}
            >
              Puoi ottenere un bonus di 50 crediti ecm se hai continuato a
              lavorare durante l'emergenza sanitaria.
            </p>
            <a href="#sezione-corsi" className="btn btn-outline-warning">
              <FontAwesomeIcon icon={faArrowCircleDown} className="mr-2" />{" "}
              Scopri di più
            </a>
          </div>
        </div>
      </section>
      <div id="sezione-corsi" className="container-fluid p-5">
        <p>
          La Commissione Nazionale per la Formazione Continua l'8 giugno 2022 ha
          deliberato quanto segue:
        </p>
        <p>
          Secondo l'articolo 5-bis del decreto legge del 19 maggio 2020, n. 34 (
          <a
            href="https://www.normattiva.it/uri-res/N2Ls?urn:nir:stato:decreto.legge:2020;34"
            title="Articolo 5-bis decreto-legge 19 maggio 2020, n. 34"
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>
          ),{" "}
          <b>
            tutti i professionisti sanitari che hanno continuato a prestare il
            proprio servizio durante l'emergenza sanitaria
          </b>
          , che ha colpito l'Italia e il mondo nel 2020 a causa dell'insorgere
          del Covid-19, hanno automaticamente diritto ad una{" "}
          <b>
            riduzione dell'obbligo formativo triennale perché vedranno già
            maturati 1/3 dei crediti totali da acquisire nel triennio.
          </b>
        </p>
        <p>
          Quanto disposto è da applicarsi in riferimento all'
          <b>obbligo formativo 2020-2022</b> così come definito dalla delibera
          della Commissione nazionale del 18 dicembre 2019.
          <br />
          Scaricare la delibera{" "}
          <a
            href="https://ape.agenas.it/documenti/Normativa/Delibera_Bonus_Crediti_08_06_2022.pdf"
            title="Delibera Commissione Nazionale per la Formazione Continua 8 Giugno 2022"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            CLICCA QUI{" "}
          </a>
        </p>
        <p>
          La Commissione Nazionale per la Formazione Continua ha dato mandato al
          Co.Ge.A.P.S. di procedere al riconoscimento del bonus ecm. In
          particolare, entro il <b>31/07/2022</b>, il Co.Ge.A.P.S. procederà
          alla riduzione di 1/3 dell&#180;obbligo formativo individuale. Tale
          riduzione{" "}
          <b>
            sarà visibile all'interno della propria area riservata nel portale
            del Co.Ge.A.P.S.
          </b>
        </p>
        <p>
          Verifica subito la tua situazione, accedi all&#180; area Riservata
          Co.Ge.A.P.S.{" "}
          <a
            href="https://application.cogeaps.it/login/"
            title="Area Riservata Co.Ge.A.P.S.Professionisti Sanitari"
            target="_blank"
            rel="noopener noreferrer"
          >
            CLICCA QUI
          </a>
        </p>
        <p>
          Vi riportiamo, di seguito, il testo dell'articolo oggetto della
          delibera:
        </p>
        <p>
          <i>
            Disposizioni in materia di formazione continua in medicina
            <br />
            1. I crediti formativi del triennio 2020-2022, da acquisire, ai
            sensi dell&#180;articolo 16-bis del decreto legislativo 30 dicembre
            1992, n. 502, e dell&#180;articolo 2, commi da 357 a 360, della
            legge 24 dicembre 2007, n. 244, attraverso l'attività di formazione
            continua in medicina, si intendono già maturati in ragione di un
            terzo per tutti i professionisti sanitari di cui alla legge 11
            gennaio 2018, n. 3, che hanno continuato a svolgere la propria
            attività professionale nel periodo dell&#180;emergenza derivante dal
            COVID-19.
          </i>
        </p>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h4
              title="Vuoi rimanere sempre aggiornato sulle novità Bonus ECM?"
              className="my-4 text-center titolo-sezione fw-bold"
              style={{ fontSize: 32 }}
            >
              Vuoi rimanere sempre aggiornato sulle novità Bonus ECM?
            </h4>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-5">
        <div className="row">
          <div className="col-12 text-center">
            <a
              href="http://d7g5i.emailsp.com/frontend/forms/Subscription.aspx?idList=28&idForm=285&guid=5ddc68f0-845c-4ca3-b4ef-d19c93033a5c"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-primary btn-lg"
            >
              Iscriviti alla newsletter
            </a>

            <p className="text-center">
              I tuoi dati non verranno condivisi con nessun Provider.
            </p>
          </div>
        </div>
      </div>
      <ScopriAltriBonusECM
        dossierFormativo={true}
        formazioneIndividuale={true}
        sperimentazioneMedicinale={true}
      />
    </Layout>
  );
}
